import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HeaderNav extends React.PureComponent {
  render() {
    const { children, isActive } = this.props;

    return (
      <nav
        className={classNames('HeaderNav', {
          'header-menu': true,
          'HeaderNav--active': isActive,
        })}
      >
        {children}
      </nav>
    );
  }
}

HeaderNav.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool.isRequired,
};

export default HeaderNav;
