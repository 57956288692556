import { browserHistory } from 'react-router';
import { getScreenBreakpoint, isMobileDevice } from './ResponsiveHelper';
import { Placement, regionStringToIdMap } from './globals/Constans.ts';
import CONFIG from '../config/config';

export const isArabicDirection = () =>
  document.documentElement.getAttribute('dir') === 'rtl';

export const getAppLanguage = () => CONFIG.language;

export function roundUp(numToRound, multiple) {
  if (multiple === 0) {
    return numToRound;
  }

  const remainder = numToRound % multiple;

  if (remainder === 0) {
    return numToRound;
  }

  return numToRound + multiple - remainder;
}

export function copyTextToClipboard(text) {
  // https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
  return new Promise((resolve, reject) => {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      window.clipboardData.setData('Text', text);
      resolve();
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea');

      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();

      try {
        document.execCommand('copy'); // Security exception may be thrown by some browsers.

        resolve();
      } catch (ex) {
        reject(ex);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  });
}

export const checkTooltipTrigger = () => (isMobileDevice() ? 'click' : 'hover');

const isScreenTooSmallForTooltip = (screen) =>
  ['xs', 'sm', 'md', 'xl'].includes(screen);

export function checkTooltipPlacement(basePlacement, altPlacement) {
  const screen = getScreenBreakpoint();

  return isScreenTooSmallForTooltip(screen)
    ? Placement[altPlacement]
    : Placement[basePlacement];
}

export const returnTooltipContainer = () => {
  const screen = getScreenBreakpoint();

  if (isScreenTooSmallForTooltip(screen)) {
    return document.getElementsByClassName('sidebar__details')[0];
  }

  return document.getElementsByClassName('page-layout__viewport')[0];
};

export const removeUnicodeEmoji = (text) => {
  const unicodeEmojiRange =
    /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;

  return text.replace(unicodeEmojiRange, '');
};

export const startAnimation = (callback) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      callback();
    });
  });
};

export const buildStringifiedLocalesForGraphQL = (locales) =>
  locales.map((i) => `"${i}"`).join(',');

export const getUrlSegments = () => {
  const { pathname } = browserHistory.getCurrentLocation();
  return pathname.split('/');
};

export const addRegionToUrl = (region) => {
  const urlSegments = getUrlSegments();

  if (Object.keys(regionStringToIdMap).includes(urlSegments[1])) {
    urlSegments[1] = region;
  }

  // temporary solution until map animation reimplement
  window.location.assign(urlSegments.join('/')); // full reloading page
};

export const isHomeUrl = () => {
  const { hash } = browserHistory.getCurrentLocation();
  return getUrlSegments().length > 1 && !hash;
};

/** disable scroll functions */
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  // eslint-disable-next-line no-param-reassign
  e = e || window.event;
  if (e.preventDefault) e.preventDefault();
  // eslint-disable-next-line no-param-reassign
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
  return null;
}

export const disableScroll = () => {
  if (window.addEventListener) {
    // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false);
    window.addEventListener('touchmove', preventDefault, { passive: false });
  } else {
    window.ontouchmove = preventDefault; // mobile
  }
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = preventDefault; // older browsers, IE
  document.onmousewheel = preventDefault;
  document.onkeydown = preventDefaultForScrollKeys;
};

export const enableScroll = () => {
  if (window.removeEventListener) {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener('touchmove', preventDefault);
  } else {
    window.ontouchmove = null;
  }
  window.onmousewheel = null;
  document.onmousewheel = null;
  window.onwheel = null;
  document.onkeydown = null;
};

export const scrollToMap = () => {
  window.scroll({ top: 0, behavior: 'smooth' });
};

export const hasMaintenanceBypass = () =>
  window.localStorage.getItem('maintenanceBypass')?.valueOf() === 'true';
