import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

import HeaderNav from '../HeaderNav/HeaderNav';
import HeaderNavItem from '../HeaderNavItem/HeaderNavItem';
import HeaderSubmenu from '../HeaderSubmenu/HeaderSubmenu';
import HeaderToggle from '../HeaderToggle';
import Logo from '../../Logo/Logo';

import './Header.scss';
import CONFIG from '../../../config/config';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedSubmenu: null,
      isMobileMenuOpened: false,
    };
  }

  componentDidMount() {
    this.bindListeners();
  }

  componentWillUnmount() {
    this.unbindListeners();
  }

  closeSubmenu = (event) => {
    const { submenuItems } = this.props;

    const { target } = event;

    const elementsWithContainedTarget = submenuItems.filter(({ ref }) =>
      ref.current.contains(target),
    );

    const shouldNotCloseSubmenu =
      elementsWithContainedTarget.length > 0 ||
      target.classList.contains('header-toggle-link');

    if (shouldNotCloseSubmenu) {
      return;
    }

    this.setState({
      openedSubmenu: null,
    });
  };

  toggleSubmenu = (event, ref) => {
    event.preventDefault();

    this.setState((prevState) => ({
      openedSubmenu: prevState.openedSubmenu ? null : ref,
    }));
  };

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpened: !prevState.isMobileMenuOpened,
    }));

    document.querySelector('main').classList.toggle('no-opacity');
    document.querySelector('.a2i-geo-footer').classList.toggle('no-opacity');
  };

  unbindListeners() {
    document.removeEventListener('click', this.closeSubmenu, false);
  }

  bindListeners() {
    document.addEventListener('click', this.closeSubmenu, false);
  }

  render() {
    const { menuItems, submenuItems } = this.props;

    const { openedSubmenu, isMobileMenuOpened } = this.state;

    return (
      <header className='Header'>
        <div className='Header__container'>
          <div className='Header__logo'>
            <div className='Header__logo-image'>
              <Logo />
            </div>
            <div className='Header__logo-item'>
              <a className='clear-link' href={CONFIG.ninetenthsUrl}>
                <Translate value='app.header.nineTenths' />
              </a>
            </div>
          </div>

          <HeaderToggle
            isActive={isMobileMenuOpened}
            handleToggle={this.toggleMobileMenu}
          />

          <HeaderNav isActive={isMobileMenuOpened}>
            {menuItems.map(({ id, location, icon, title }) => (
              <HeaderNavItem
                key={id}
                icon={icon}
                location={location}
                title={title}
              />
            ))}

            {submenuItems.map(({ id, ref, submenuTitle, items }) => (
              <HeaderSubmenu
                key={id}
                forwardedRef={ref}
                forwardedId={id}
                submenuTitle={submenuTitle}
                clickedItem={openedSubmenu}
                items={items}
                onClick={this.toggleSubmenu}
              >
                {items.map(({ id: subId, location, icon, title }) => (
                  <HeaderNavItem
                    key={subId}
                    title={title}
                    location={location}
                    icon={icon}
                    isSubmenuItem
                  />
                ))}
              </HeaderSubmenu>
            ))}
          </HeaderNav>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  menuItems: PropTypes.array,
  submenuItems: PropTypes.array,
};

export { HeaderNav, HeaderNavItem, HeaderSubmenu };

export default Header;
