/* eslint-disable import/no-import-module-exports --
 * the file uses `module` variable to config hot-module-replacement,
 * what causes eslint error. The issue is more describe and raise here:
 * https://github.com/airbnb/javascript/issues/2496 */

import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';

import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

import App from './components/App';
import CONFIG from './config/config';

import './styles/main.scss';
import StaticHeader from './components/Header';
import MaintenancePage from './components/MaintenancePage';
import { hasMaintenanceBypass } from './helpers/AppHelper';
import apolloClient from './apolloClient';
import store from './store';

// Render Setup
// ------------------------------------

// Create element is dedicated for unit tests
const MOUNT_NODE =
  document.getElementById('root') || document.createElement('div');

const HEADER_NODE = document.querySelector('.a2i-geo-header');

const FOOTER_NODE = document.querySelector('.a2i-geo-footer');

const PAGE_NODE = document.querySelector('.page');

const render = () => {
  Sentry.init({
    dsn: CONFIG.sentryDsn,
    environment: CONFIG.sentryEnvironment,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  if (CONFIG.isMaintenanceMode && !hasMaintenanceBypass()) {
    ReactDOM.render(<MaintenancePage />, PAGE_NODE);
  } else {
    ReactDOM.render(
      <ApolloProvider store={store} client={apolloClient}>
        <App store={store} />
      </ApolloProvider>,
      MOUNT_NODE,
    );
  }

  ReactDOM.render(<StaticHeader />, HEADER_NODE);

  // eslint-disable-next-line no-undef, no-new
  new nine10ths.components.Footer(FOOTER_NODE, {
    lang: 'ar',
  });
};

render();
