import app from './translations/app';
import breadcrumbs from './translations/breadcrumbs';
import businessChart from './translations/businessChart';
import businessTab from './translations/businessTab';
import demographicChart from './translations/demographicChart';
import demographicTab from './translations/demographicTab';
import errorMessage from './translations/errorMessage';
import map from './translations/map';
import mapAutocomplete from './translations/mapAutocomplete';
import places from './translations/places';
import sectors from './translations/sectors';
import sectorsSidebar from './translations/sectorSidebar';
import incomeChart from './translations/incomeChart';
import onboarding from './translations/onboarding';
import maintenancePage from './translations/maintenancePage';
import spendingChart from './translations/spendingChart';
import downloadTooltip from './translations/downloadTooltip';
import downloadModal from './translations/downloadModal';
import renderPdf from './translations/renderPdf';
import saveResults from './translations/saveResults';
import saveModal from './translations/saveModal';
import csvReport from './translations/csvReport';

export default {
  app,
  breadcrumbs,
  businessTab,
  businessChart,
  demographicTab,
  demographicChart,
  errorMessage,
  map,
  mapAutocomplete,
  places,
  sectors,
  sectorsSidebar,
  incomeChart,
  onboarding,
  maintenancePage,
  spendingChart,
  downloadTooltip,
  downloadModal,
  renderPdf,
  saveResults,
  saveModal,
  csvReport,
};
